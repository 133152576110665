/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "node_modules/primeicons/primeicons.css";

@layer base {
  html {
    font-family: "Nunito Sans", sans-serif;
  }
}

@layer components {
  .bg-primary {
    @apply bg-[#087AC5];
  }

  .bg-secondary {
    @apply bg-[#f2f2f2];
  }

  .text-primary {
    @apply text-[#087AC5];
  }

  .text-secondary-light {
    @apply text-[#3c3c3c];
  }

  .border-primary {
    @apply border-[#087AC5];
  }

  .bg-landing-banner {
    background-image: url("../src/assets/bg_landing.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: -134px;
  }

  .bg-faq {
    background-image: url("../src/assets/bg_faq.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media only screen and (max-width: 1400px) {
    .bg-faq {
      background-size: contain;
    }
  }

  .bg-card-type {
    background-image: url("../src/assets/bg_our_card_type.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media only screen and (max-width: 420px) {
    .bg-card-type {
      background-image: none;
    }
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .p-button {
    background: #087ac5;
    color: #ffffff;
    border: solid 1px #087ac5;
  }

  .p-button:enabled:hover {
    background: #087ac5;
    color: #ffffff;
    border: solid 1px #087ac5;
  }

  .p-dropdown {
    border-radius: 1px;
  }
}

@media not all and (min-width: 640px) {
  .bg-landing-banner {
    top: -91px;
    z-index: -1;
  }
}

.p-steps {
  padding: 30px 10px;

  .p-steps-item {
    &:before {
      top: 80%;
    }

    .p-menuitem-link {
      background-color: transparent;

      .p-steps-number {
        font-size: 1rem;
      }
    }
  }
}

.p-fileupload {
  .p-message {
    display: none;

    .p-message-wrapper {
      display: none;
    }
  }
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
}

.flag {
  background: url(./assets/media/flags/flags_responsive.png) no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

.flag-ad {
  background-position: 0 0.413223%;
}

.flag-ae {
  background-position: 0 0.826446%;
}

.flag-af {
  background-position: 0 1.239669%;
}

.flag-ag {
  background-position: 0 1.652893%;
}

.flag-ai {
  background-position: 0 2.066116%;
}

.flag-al {
  background-position: 0 2.479339%;
}

.flag-am {
  background-position: 0 2.892562%;
}

.flag-an {
  background-position: 0 3.305785%;
}

.flag-ao {
  background-position: 0 3.719008%;
}

.flag-aq {
  background-position: 0 4.132231%;
}

.flag-ar {
  background-position: 0 4.545455%;
}

.flag-as {
  background-position: 0 4.958678%;
}

.flag-at {
  background-position: 0 5.371901%;
}

.flag-au {
  background-position: 0 5.785124%;
}

.flag-aw {
  background-position: 0 6.198347%;
}

.flag-az {
  background-position: 0 6.61157%;
}

.flag-ba {
  background-position: 0 7.024793%;
}

.flag-bb {
  background-position: 0 7.438017%;
}

.flag-bd {
  background-position: 0 7.85124%;
}

.flag-be {
  background-position: 0 8.264463%;
}

.flag-bf {
  background-position: 0 8.677686%;
}

.flag-bg {
  background-position: 0 9.090909%;
}

.flag-bh {
  background-position: 0 9.504132%;
}

.flag-bi {
  background-position: 0 9.917355%;
}

.flag-bj {
  background-position: 0 10.330579%;
}

.flag-bm {
  background-position: 0 10.743802%;
}

.flag-bn {
  background-position: 0 11.157025%;
}

.flag-bo {
  background-position: 0 11.570248%;
}

.flag-br {
  background-position: 0 11.983471%;
}

.flag-bs {
  background-position: 0 12.396694%;
}

.flag-bt {
  background-position: 0 12.809917%;
}

.flag-bv {
  background-position: 0 13.22314%;
}

.flag-bw {
  background-position: 0 13.636364%;
}

.flag-by {
  background-position: 0 14.049587%;
}

.flag-bz {
  background-position: 0 14.46281%;
}

.flag-ca {
  background-position: 0 14.876033%;
}

.flag-cc {
  background-position: 0 15.289256%;
}

.flag-cd {
  background-position: 0 15.702479%;
}

.flag-cf {
  background-position: 0 16.115702%;
}

.flag-cg {
  background-position: 0 16.528926%;
}

.flag-ch {
  background-position: 0 16.942149%;
}

.flag-ci {
  background-position: 0 17.355372%;
}

.flag-ck {
  background-position: 0 17.768595%;
}

.flag-cl {
  background-position: 0 18.181818%;
}

.flag-cm {
  background-position: 0 18.595041%;
}

.flag-cn {
  background-position: 0 19.008264%;
}

.flag-co {
  background-position: 0 19.421488%;
}

.flag-cr {
  background-position: 0 19.834711%;
}

.flag-cu {
  background-position: 0 20.247934%;
}

.flag-cv {
  background-position: 0 20.661157%;
}

.flag-cx {
  background-position: 0 21.07438%;
}

.flag-cy {
  background-position: 0 21.487603%;
}

.flag-cz {
  background-position: 0 21.900826%;
}

.flag-de {
  background-position: 0 22.31405%;
}

.flag-dj {
  background-position: 0 22.727273%;
}

.flag-dk {
  background-position: 0 23.140496%;
}

.flag-dm {
  background-position: 0 23.553719%;
}

.flag-do {
  background-position: 0 23.966942%;
}

.flag-dz {
  background-position: 0 24.380165%;
}

.flag-ec {
  background-position: 0 24.793388%;
}

.flag-ee {
  background-position: 0 25.206612%;
}

.flag-eg {
  background-position: 0 25.619835%;
}

.flag-eh {
  background-position: 0 26.033058%;
}

.flag-er {
  background-position: 0 26.446281%;
}

.flag-es {
  background-position: 0 26.859504%;
}

.flag-et {
  background-position: 0 27.272727%;
}

.flag-fi {
  background-position: 0 27.68595%;
}

.flag-fj {
  background-position: 0 28.099174%;
}

.flag-fk {
  background-position: 0 28.512397%;
}

.flag-fm {
  background-position: 0 28.92562%;
}

.flag-fo {
  background-position: 0 29.338843%;
}

.flag-fr {
  background-position: 0 29.752066%;
}

.flag-ga {
  background-position: 0 30.165289%;
}

.flag-gd {
  background-position: 0 30.578512%;
}

.flag-ge {
  background-position: 0 30.991736%;
}

.flag-gf {
  background-position: 0 31.404959%;
}

.flag-gh {
  background-position: 0 31.818182%;
}

.flag-gi {
  background-position: 0 32.231405%;
}

.flag-gl {
  background-position: 0 32.644628%;
}

.flag-gm {
  background-position: 0 33.057851%;
}

.flag-gn {
  background-position: 0 33.471074%;
}

.flag-gp {
  background-position: 0 33.884298%;
}

.flag-gq {
  background-position: 0 34.297521%;
}

.flag-gr {
  background-position: 0 34.710744%;
}

.flag-gs {
  background-position: 0 35.123967%;
}

.flag-gt {
  background-position: 0 35.53719%;
}

.flag-gu {
  background-position: 0 35.950413%;
}

.flag-gw {
  background-position: 0 36.363636%;
}

.flag-gy {
  background-position: 0 36.77686%;
}

.flag-hk {
  background-position: 0 37.190083%;
}

.flag-hm {
  background-position: 0 37.603306%;
}

.flag-hn {
  background-position: 0 38.016529%;
}

.flag-hr {
  background-position: 0 38.429752%;
}

.flag-ht {
  background-position: 0 38.842975%;
}

.flag-hu {
  background-position: 0 39.256198%;
}

.flag-id {
  background-position: 0 39.669421%;
}

.flag-ie {
  background-position: 0 40.082645%;
}

.flag-il {
  background-position: 0 40.495868%;
}

.flag-in {
  background-position: 0 40.909091%;
}

.flag-io {
  background-position: 0 41.322314%;
}

.flag-iq {
  background-position: 0 41.735537%;
}

.flag-ir {
  background-position: 0 42.14876%;
}

.flag-is {
  background-position: 0 42.561983%;
}

.flag-it {
  background-position: 0 42.975207%;
}

.flag-jm {
  background-position: 0 43.38843%;
}

.flag-jo {
  background-position: 0 43.801653%;
}

.flag-jp {
  background-position: 0 44.214876%;
}

.flag-ke {
  background-position: 0 44.628099%;
}

.flag-kg {
  background-position: 0 45.041322%;
}

.flag-kh {
  background-position: 0 45.454545%;
}

.flag-ki {
  background-position: 0 45.867769%;
}

.flag-km {
  background-position: 0 46.280992%;
}

.flag-kn {
  background-position: 0 46.694215%;
}

.flag-kp {
  background-position: 0 47.107438%;
}

.flag-kr {
  background-position: 0 47.520661%;
}

.flag-kw {
  background-position: 0 47.933884%;
}

.flag-ky {
  background-position: 0 48.347107%;
}

.flag-kz {
  background-position: 0 48.760331%;
}

.flag-la {
  background-position: 0 49.173554%;
}

.flag-lb {
  background-position: 0 49.586777%;
}

.flag-lc {
  background-position: 0 50%;
}

.flag-li {
  background-position: 0 50.413223%;
}

.flag-lk {
  background-position: 0 50.826446%;
}

.flag-lr {
  background-position: 0 51.239669%;
}

.flag-ls {
  background-position: 0 51.652893%;
}

.flag-lt {
  background-position: 0 52.066116%;
}

.flag-lu {
  background-position: 0 52.479339%;
}

.flag-lv {
  background-position: 0 52.892562%;
}

.flag-ly {
  background-position: 0 53.305785%;
}

.flag-ma {
  background-position: 0 53.719008%;
}

.flag-mc {
  background-position: 0 54.132231%;
}

.flag-md {
  background-position: 0 54.545455%;
}

.flag-me {
  background-position: 0 54.958678%;
}

.flag-mg {
  background-position: 0 55.371901%;
}

.flag-mh {
  background-position: 0 55.785124%;
}

.flag-mk {
  background-position: 0 56.198347%;
}

.flag-ml {
  background-position: 0 56.61157%;
}

.flag-mm {
  background-position: 0 57.024793%;
}

.flag-mn {
  background-position: 0 57.438017%;
}

.flag-mo {
  background-position: 0 57.85124%;
}

.flag-mp {
  background-position: 0 58.264463%;
}

.flag-mq {
  background-position: 0 58.677686%;
}

.flag-mr {
  background-position: 0 59.090909%;
}

.flag-ms {
  background-position: 0 59.504132%;
}

.flag-mt {
  background-position: 0 59.917355%;
}

.flag-mu {
  background-position: 0 60.330579%;
}

.flag-mv {
  background-position: 0 60.743802%;
}

.flag-mw {
  background-position: 0 61.157025%;
}

.flag-mx {
  background-position: 0 61.570248%;
}

.flag-my {
  background-position: 0 61.983471%;
}

.flag-mz {
  background-position: 0 62.396694%;
}

.flag-na {
  background-position: 0 62.809917%;
}

.flag-nc {
  background-position: 0 63.22314%;
}

.flag-ne {
  background-position: 0 63.636364%;
}

.flag-nf {
  background-position: 0 64.049587%;
}

.flag-ng {
  background-position: 0 64.46281%;
}

.flag-ni {
  background-position: 0 64.876033%;
}

.flag-nl {
  background-position: 0 65.289256%;
}

.flag-no {
  background-position: 0 65.702479%;
}

.flag-np {
  background-position: 0 66.115702%;
}

.flag-nr {
  background-position: 0 66.528926%;
}

.flag-nu {
  background-position: 0 66.942149%;
}

.flag-nz {
  background-position: 0 67.355372%;
}

.flag-om {
  background-position: 0 67.768595%;
}

.flag-pa {
  background-position: 0 68.181818%;
}

.flag-pe {
  background-position: 0 68.595041%;
}

.flag-pf {
  background-position: 0 69.008264%;
}

.flag-pg {
  background-position: 0 69.421488%;
}

.flag-ph {
  background-position: 0 69.834711%;
}

.flag-pk {
  background-position: 0 70.247934%;
}

.flag-pl {
  background-position: 0 70.661157%;
}

.flag-pm {
  background-position: 0 71.07438%;
}

.flag-pn {
  background-position: 0 71.487603%;
}

.flag-pr {
  background-position: 0 71.900826%;
}

.flag-pt {
  background-position: 0 72.31405%;
}

.flag-pw {
  background-position: 0 72.727273%;
}

.flag-py {
  background-position: 0 73.140496%;
}

.flag-qa {
  background-position: 0 73.553719%;
}

.flag-re {
  background-position: 0 73.966942%;
}

.flag-ro {
  background-position: 0 74.380165%;
}

.flag-rs {
  background-position: 0 74.793388%;
}

.flag-ru {
  background-position: 0 75.206612%;
}

.flag-rw {
  background-position: 0 75.619835%;
}

.flag-sa {
  background-position: 0 76.033058%;
}

.flag-sb {
  background-position: 0 76.446281%;
}

.flag-sc {
  background-position: 0 76.859504%;
}

.flag-sd {
  background-position: 0 77.272727%;
}

.flag-se {
  background-position: 0 77.68595%;
}

.flag-sg {
  background-position: 0 78.099174%;
}

.flag-sh {
  background-position: 0 78.512397%;
}

.flag-si {
  background-position: 0 78.92562%;
}

.flag-sj {
  background-position: 0 79.338843%;
}

.flag-sk {
  background-position: 0 79.752066%;
}

.flag-sl {
  background-position: 0 80.165289%;
}

.flag-sm {
  background-position: 0 80.578512%;
}

.flag-sn {
  background-position: 0 80.991736%;
}

.flag-so {
  background-position: 0 81.404959%;
}

.flag-sr {
  background-position: 0 81.818182%;
}

.flag-ss {
  background-position: 0 82.231405%;
}

.flag-st {
  background-position: 0 82.644628%;
}

.flag-sv {
  background-position: 0 83.057851%;
}

.flag-sy {
  background-position: 0 83.471074%;
}

.flag-sz {
  background-position: 0 83.884298%;
}

.flag-tc {
  background-position: 0 84.297521%;
}

.flag-td {
  background-position: 0 84.710744%;
}

.flag-tf {
  background-position: 0 85.123967%;
}

.flag-tg {
  background-position: 0 85.53719%;
}

.flag-th {
  background-position: 0 85.950413%;
}

.flag-tj {
  background-position: 0 86.363636%;
}

.flag-tk {
  background-position: 0 86.77686%;
}

.flag-tl {
  background-position: 0 87.190083%;
}

.flag-tm {
  background-position: 0 87.603306%;
}

.flag-tn {
  background-position: 0 88.016529%;
}

.flag-to {
  background-position: 0 88.429752%;
}

.flag-tp {
  background-position: 0 88.842975%;
}

.flag-tr {
  background-position: 0 89.256198%;
}

.flag-tt {
  background-position: 0 89.669421%;
}

.flag-tv {
  background-position: 0 90.082645%;
}

.flag-tw {
  background-position: 0 90.495868%;
}

.flag-ty {
  background-position: 0 90.909091%;
}

.flag-tz {
  background-position: 0 91.322314%;
}

.flag-ua {
  background-position: 0 91.735537%;
}

.flag-ug {
  background-position: 0 92.14876%;
}

.flag-gb,
.flag-uk {
  background-position: 0 92.561983%;
}

.flag-um {
  background-position: 0 92.975207%;
}

.flag-us {
  background-position: 0 93.38843%;
}

.flag-uy {
  background-position: 0 93.801653%;
}

.flag-uz {
  background-position: 0 94.214876%;
}

.flag-va {
  background-position: 0 94.628099%;
}

.flag-vc {
  background-position: 0 95.041322%;
}

.flag-ve {
  background-position: 0 95.454545%;
}

.flag-vg {
  background-position: 0 95.867769%;
}

.flag-vi {
  background-position: 0 96.280992%;
}

.flag-vn {
  background-position: 0 96.694215%;
}

.flag-vu {
  background-position: 0 97.107438%;
}

.flag-wf {
  background-position: 0 97.520661%;
}

.flag-ws {
  background-position: 0 97.933884%;
}

.flag-ye {
  background-position: 0 98.347107%;
}

.flag-za {
  background-position: 0 98.760331%;
}

.flag-zm {
  background-position: 0 99.173554%;
}

.flag-zr {
  background-position: 0 99.586777%;
}

.flag-zw {
  background-position: 0 100%;
}

.p-button-label {
  font-weight: 400;
}

.p-togglebutton.p-button {
  background: #e5e7eb;
  border: 1px solid #e5e7eb;
  color: #6b7280;
  border: 0;
  transition: none;
  border-radius: 0;
}

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover {
  background: #bfdbff;
  border-color: #bfdbff;
  color: #3b82f6;
}

/* modified css */
.p-inputtext {
  padding: 0.5rem 0.75rem !important;
}

#pr_id_5_list {
  max-width: 330px;
}

.color-picker {
  position: absolute !important;
  top: 65px !important;
  left: 260px !important;
}

.bg-white.border.rounded-lg.p-5.mb-2.w-full.drop-shadow {
  z-index: 1;
  position: relative;
}

.flex.user_imgs svg {
  // background: #3B82F6;
  color: #fff;
  border-radius: 100%;
  padding: 3px;
  text-align: center;
  margin: 12px auto 0;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #087ac5;
}

.flex.user_imgs {
  display: block;
  position: relative;
}

.user_imgs img {
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: 1500px) {
    margin: 0;
    width: 100%;
    border-radius: 20px;
    margin-top: 15px;
  }
}

.user_imgs a {
  position: absolute;
  top: -15px;
  right: -34px;

  @media screen and (max-width: 1500px) {
    position: absolute;
    top: -15px;
    right: -34px;
  }
}

a.bg-gray-200 {
  margin-top: 25px;
  min-height: 190px;

  @media screen and (max-width: 1500px) {
    min-height: 240px;
  }
}

form .bg-white svg {
  color: rgb(8, 122, 197);
}

.flex.items-center.lg\:w-1\/3 {
  margin-top: 25px;
}

a.bg-gray-200.final_step {
  min-height: inherit;
}

.key_icon {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: auto;
}

.key_icon svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  color: rgb(8, 122, 197);
}

.key_icon button {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  padding: 10px;
}

.key_icon #perLang-en {
  background: #3b82f6;
  color: #fff;
}

.key_icon #perLang-ar {
  background: #3b82f6;
  color: #fff;
}

.key_icon button:first-child {
  border-right: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.key_icon button:nth-child(2) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.key_icon button span {
  display: none;
}

.keyboard_icon {
  margin-left: 10px;
  margin-top: 10px;
}

.flex.w-full.social_icons {
  justify-content: start;
  margin: 20px 0 30px;
}

.social_icons ul li:last-child {
  margin-right: 0;
}

.view_block .flex.w-full.social_icons li.mr-3 {
  margin-right: 8%;
}

.flex.w-full.social_icons li.mr-3 {
  margin-right: 12%;
}

.w-full .contact_info {
  margin: 20px 0 30px;
}

.user_profile .profile-img {
  margin: 0 auto;
  border-radius: 0.4rem;
  border-width: 2.5px;
  width: 164px;
  height: 164px;
  object-fit: cover;
}

.my_web_link {
  margin: 0 0 20px;
}

.video_info {
  margin: 25px 0 30px;
}

.image_info {
  margin: 0 0 30px;
}

.image_info img {
  margin: 0 auto;
}

.link_list {
  padding: 0 0 10px;
}

.video_blk iframe {
  width: 100%;
}

.video_blkk iframe {
  width: 100%;
}

.userr_name {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.user_job,
.user_company {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
}
.p-dialog-content {
  overflow-y: visible !important;
}

nav#sidenav {
  padding: 0;
}

nav#sidenav ul {
  padding: 0;
}

nav#sidenav ul li {
  font-weight: 600;
  padding-left: 10px;
}

nav#sidenav ul li:hover {
  background: #282727;
}

nav#sidenav ul li:hover a.text-white {
  color: #fff;
}

nav#sidenav ul li:hover a.text-white svg {
  color: #fff;
}

nav p:nth-child(2) {
  font-weight: bold;
  text-transform: capitalize;
}

.bg-primary {
  background-color: #087ac5;
}

.border-primary {
  border-color: #087ac5;
}

.contact_detail .view_block img {
  margin: 0 auto;
}

/*direction styles*/
.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;

  h3 {
    text-align: right;
  }

  p-selectbutton.p-element {
    direction: rtl;
  }

  .p-buttonset .p-button:last-of-type {
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  .p-buttonset .p-button:first-of-type {
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 1px solid #fff;
  }

  .p-selectbutton .p-button {
    border: 1px solid #ced4da;
  }

  button.p-element.flex.justify-center {
    margin-right: 0;
  }

  .p-input-icon-right > .p-icon-wrapper,
  .p-input-icon-right > i:last-of-type {
    right: 93%;
  }

  .p-button .p-button-icon-left {
    margin-right: -2px;
    margin-left: 0.5rem;
  }

  .p-fileupload.p-fileupload-basic.p-component {
    margin-left: 6px;
  }

  button.p-ripple.p-element.p-button-text.p-button.p-component span {
    transform: rotate(180deg);
    margin-left: -1px;
  }

  .p-dialog-content .sm\:text-left {
    text-align: center;
    width: 100%;
    margin: 0;
  }

  .p-dialog-content .text-gray-500 {
    text-align: center;
  }

  .rounded-md {
    h3 {
      text-align: center;
    }
  }

  button {
    &.text-green-900 {
      margin-left: 23px;
      margin-right: 0;
    }

    &.bg-white.border.mr-1 {
      margin-left: 0.25rem;
      margin-right: 0;
    }
  }

  .flex.w-full.social_icons li.mr-3 {
    margin-right: 0;
    margin-left: 28px;
  }

  ul {
    li {
      .mr-5 {
        margin-left: 1.25rem;
        margin-right: 0;
      }
    }
  }

  .text-gray-500 {
    text-align: right;
  }

  .mr-5 {
    margin-left: 1.25rem;
    margin-right: 0;
  }
}

.bg-white .mb-7 {
  margin-bottom: 1.875rem;
}

.p-dialog {
  max-width: 100%;
  width: 22rem;
  top: 22px;
  right: 0;
  margin: 0 auto;
  display: block;
  border-radius: 0;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  overflow-y: auto !important;
}
.multiLangPopup .p-dialog {
  width: min-content !important;
  top: 2px !important;
  min-width: 350px;
}
.multiLangPopup .p-dialog.p-element .p-dialog-content {
  padding: 0 1.25rem 1.25rem !important;
}
.bulk-update-instructions .p-dialog {
  // width: auto !important;
  border-radius: 5px !important;
}

.add_notes_popup {
  .p-dialog {
    width: 40rem;

    .p-dialog.p-element .p-dialog-content {
      padding: 0 1.25rem;
    }

    &.p-dialog.p-element {
      .p-dialog-header {
        display: none;
      }

      .p-dialog-content {
        padding: 0;

        h3 {
          background-color: #000;
          color: #fff;
          padding: 0.7rem 1rem;
        }

        .note_content {
          padding: 1.25rem;
          border: none;
          background: #f2f2f2;

          textarea {
            width: 100%;
            resize: none;
          }

          span {
            margin-left: 6px;
          }

          p-calendar.p-element {
            span {
              height: 30px;
              margin-top: -3px;

              input {
                padding: 0 !important;
                border: 1px solid #ccc;
              }
            }
          }
        }
      }
    }
  }
}

.contact_remainder {
  .p-dialog {
    width: 45rem;

    &.p-dialog.p-element .p-dialog-content {
      padding: 0;
    }

    &.p-dialog.p-element {
      .p-dialog-header {
        display: none;
      }
    }

    h4 {
      padding: 0.7rem 1rem;
      font-size: 16px;
      font-weight: 500;
    }

    .radio_block {
      padding: 0.7rem 1rem;
    }
  }

  .remind_inner {
    padding: 0.7rem 1.3rem;
  }
}

.p-dialog-mask.p-component-overlay {
  width: 100%;
}

.p-dialog .p-dialog-header {
  border-radius: 0;
}

.p-dialog .p-dialog-content:last-of-type {
  border-radius: 0;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  border: 1px solid #eaeaea;
  background: #f2f2f2;
  border-bottom: 0;
  color: #000;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
}

.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid #eaeaea;
  // background: #f2f2f2;
  border-top: 0;
}

.qrcode canvas {
  text-align: center;
  margin: 0 auto;
  border: 3px solid #000;
  border-radius: 4px;
}

.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  display: none;
}

.p-dialog .p-dialog-content {
  padding: 6px;
}

.p-dialog .p-dialog-header {
  padding: 6px;
}

.top_heading {
  background: transparent;
  border: 1px solid #dee2e6;
  margin-bottom: 5px;
  padding: 1rem;
  border-radius: 6px;
}

.top_heading p {
  display: inline-block;
  font-size: 1rem;
  color: #000;
  font-weight: 700;
  width: 260px;
  vertical-align: middle;
}

.top_heading svg {
  display: inline-block;
  width: 20px;
  float: right;
  height: 20px;
  color: #000;
  font-weight: 700;
  fill: #000;
  vertical-align: middle;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent;
  padding: 1rem;
  color: #000;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
}

.p-accordion p-accordiontab .p-accordion-tab {
  position: relative;
}

.menu_border {
  border-bottom: #ccc 2px solid;
}

.right_icon {
  position: absolute;
  top: 13px;
  right: 15px;
  z-index: 100;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: transparent;
}

button.scan_me {
  margin: 0 auto;
  display: block;
  width: 150px;
  border-radius: 0 0 4px 4px;
  background: #000;
  margin-top: -3px;
  padding: 6px;
  color: #ffff;
  font-family: "Nunito Sans", sans-serif;
}

.profile_name {
  font-weight: 800;
  line-height: 2rem;
  font-size: 26px;
  text-transform: capitalize;
  margin-bottom: 6px;
  word-wrap: break-word;
  margin-top: 0.4rem;
  word-break: break-word;
  text-transform: none;
  text-overflow: ellipsis;
}

.user-profile {
  .name {
    text-transform: none;
  }
}

.designation {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 5px;
  line-height: 1rem;
  text-overflow: ellipsis;
}
.designation2 {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1rem;
  text-overflow: ellipsis;
  font-style: italic;
}
.companyName {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1rem;
}

.headlineText {
  font-size: 15px;
  font-weight: 600;
  margin-top: -8px;
  line-height: 18px;
  text-align: center;
  word-break: break-word;
}

.text-gray-500.video_desc {
  font-weight: 500;
  margin-top: 0px;
}

.text-gray-500.image_desc {
  font-weight: 500;
  margin-top: 0px;
}

.exchange_contact .p-dialog .p-dialog-header {
  padding: 1.25rem;
}

.exchange_contact .p-dialog .p-dialog-content:last-of-type {
  padding: 0 1.25rem 1.25rem;
}

.mt-5.mb-5.flex.w-full button.border.text-sm.bg-\[\#004131\] {
  border: 1px solid rgb(0, 65, 49);
}

.bg-white.px-3.py-3.w-full.rtl img {
  margin: 0 auto;
}

.bg-white.px-3.py-3.w-full.ltr img {
  margin: 0 auto;
}

.p-dialog .p-dialog-header {
  padding: 6px;
  color: #fff;
  background: #fff;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1rem;
  color: #000;
}

.view_block {
  width: 32%;
}

.view_block div.relative.contact_view_block {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  overflow: auto;
  border-radius: 1.25rem;
  min-height: 620px;
}

.contact_detail .view_block > div {
  min-height: auto;
}

.contact_detail .view_block > div .h-full {
  min-height: 620px;
}

.view_block > div .f_card {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  position: absolute;
  bottom: 0;
}

.view_block .qrcode canvas {
  border: none;
}

.whatsapp_icon svg {
  margin-top: -54px;
}

.whatsapp_icon .text-sm,
.whatsapp_icon input,
.whatsapp_icon button {
  font-family: "Nunito Sans", sans-serif;
}

.p-accordion .p-accordion-content button {
  font-family: "Nunito Sans", sans-serif;
}

.social .p-dropdown {
  width: 100%;
}

.Profile_background {
  border-radius: 0.5rem;
  box-sizing: border-box;
  overflow: auto;
  margin-top: 7rem;
}

.font-family {
  font-family: "Nunito Sans", sans-serif;
}

.w-full.mt-32 {
  margin-top: 7rem;
}

.pop_input input {
  width: 232px;
}

.whatsapp_icon .pop_input input {
  width: 205px;
}

.p-ripple.p-element.p-button.p-component {
  padding: 5px 9px 5px;
}

button.p-ripple.p-element.p-button-text.p-button.p-component {
  padding: 5px 0;
}

.p-ripple.p-element.p-button.p-component span {
  font-size: 0.8rem;
  font-weight: 600;
}

// select.bg-gray-50,
// input.bg-gray-50 {
//     background: none;
// }

.p-datatable .p-datatable-tbody tr p-table table {
  padding: 15px;
}

.p-datatable .p-datatable-tbody tr p-table table tr th {
  background: #087ac5;
  color: #fff;
}

.p-datatable
  .p-datatable-tbody
  tr
  p-table
  table
  tr
  th.p-sortable-column:not(.p-highlight):hover {
  background: #087ac5;
  color: #fff;
}

.order_table .p-button.p-button-icon-only .p-button-icon-left,
.order_table .p-button.p-button-icon-only .p-button-icon-right {
  color: rgb(108, 117, 125);
}

.p-datatable .p-datatable-tbody tr p-table table tr td {
  border: none;
}

.p-dialog.p-element .p-dialog-header {
  padding: 0.8rem 1.25rem;
}

.p-dialog.p-element .p-dialog-content {
  padding: 1.25rem;
}

.whitespace-nowrap {
  white-space: nowrap;
  line-height: 2rem;
  height: 2em;
  /* height is 2x line-height, so two lines will display */
  overflow: hidden;
}

.signup_form {
  width: 100%;
  max-width: 451px;
}

/* HAMBURGER MENU */

.hamburger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
  background: #333;
}

.hamburger__top-bun {
  transform: translateY(-5px);
}

.hamburger__bottom-bun {
  transform: translateY(3px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(-1px);
}

.open .hamburger__top-bun {
  transform: rotate(45deg) translateY(0px);
}

.open .hamburger__bottom-bun {
  transform: rotate(-45deg) translateY(0px);
}

.p-dialog.p-element .text-gray-500 {
  color: #000;
}

form .p-selectbutton .p-button.p-highlight {
  border-color: #999797;
}

form .p-selectbutton .p-button:focus,
form .p-selectbutton .p-button:hover {
  box-shadow: inherit;
}

.p-inputswitch {
  width: 3rem;
  height: 1.5rem;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 1rem;
  height: 1rem;
  left: 0.4rem;
  margin-top: -0.51rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.2rem);
}

.p-inputswitch.p-inputswitch-checked.p-focus,
.p-inputswitch.p-focus {
  border: none;
  outline: none;
  box-shadow: inherit;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: inherit;
}

.p-button {
  background: #087ac5;
  color: #ffffff;
  border: solid 1px #087ac5;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #087ac5;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: #087ac5;
}

.contact_info a {
  word-break: break-word;
}

.contact_infotext {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}

.contact_infolabel {
  font-size: 12px;
  font-weight: 400;
  // margin-bottom: 5px;
}
.contact_infolabel.rtl {
  text-align: right;
}

.w-full .contact_info svg {
  margin-top: -17px;
}

.w-full .contact_info .addr_line svg {
  margin-top: -6px;
}

.custom_linktext {
  font-size: 15px;
}
.image_information img {
  margin: 0 auto;
}
img,
video {
  max-width: 100%;
  height: auto;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
figure {
  margin: 0;
}

/* burger menu */
.b-navbar {
  max-width: 71.25rem;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  justify-content: center;
  color: black;
}

.b-navbar__toggle {
  display: flex;
  justify-content: flex-end;
  margin: 0.7em 7em 0.7em 0;
}

.b-navbar__icon {
  color: #fff;
  margin: auto;
}

.b-navbar__nav {
  display: flex;
  text-transform: uppercase;
  list-style: none;
  padding-top: 0.8em;
}

.b-navbar__link {
  text-decoration: none;
  padding: 0 1.5em;
  font-size: 0.9rem;
  color: black;
}

.navbar-toggler {
  background-color: rgba(38, 38, 38, 0);
}

/* Media Queries */

.b-navbar__button {
  justify-content: center !important;
  border: 0.1em solid transparent;
  border-radius: 10%;
  background-color: transparent;
  font-size: 1.2rem;
}

/*Login Form*/
.login_form {
  background: #fff;
  padding: 25px 20px;
  border-radius: 4px;
}

.p-inputgroup h1 {
  color: #000 !important;
}

.card h1 {
  color: #000 !important;
}

.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:focus {
  background: #fff;
  color: #000;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.3);
}

form .p-selectbutton .p-button {
  background: #000;
  color: #fff;
}

// .p-ripple.p-element.p-button.p-component:nth-child(2) {
//     background: #000;
//     color: #fff;
// }
.p-selectbutton .p-button.p-highlight:hover {
  background: #fff;
  border-color: #999797;
  color: #000;
}

.p-selectbutton .p-button {
  border: 1px solid #ced4da;
}

.text-white.companyname {
  word-break: break-word;
}

.Profile_background .name {
  word-break: break-word;
}

body {
  background-color: #000;
}

.p-component {
  font-family: "Nunito Sans", sans-serif;
}

.rtl .video_info p.text-gray-500 {
  text-align: center;
}

.rtl .image_info h3.text-gray-500 {
  text-align: center;
}

.rtl form label {
  direction: rtl;
}

.rtl .preview_butt {
  margin-right: 1.25rem;
}

.rtl button.bg-primary:last-child {
  margin-right: 1.25rem;
}

.rtl .p-dialog-content label.text-gray-500 {
  text-align: right;
}

.rtl .flex .lg\:mr-3 {
  margin-left: 0.75rem;
}

footer.rtl .mb-2.mr-10 {
  margin-left: 2.5rem;
  margin-right: 0;
  width: 100%;
}

footer.rtl .lg\:mr-5 {
  margin-left: 1.25rem;
  margin-right: 0;
}

footer.rtl li .mr-3 {
  margin-left: 0.75rem;
  margin-right: 0;
}

.rtl .bg-black .mr-1 {
  margin-left: 0.25rem;
  margin-right: 0;
}

/*Menu Design dropdown*/
.dropdown-content[_ngcontent-serverApp-c21] {
  /* display: none; */
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 48px;
  border-radius: 4px;
}

.dropdown-content[_ngcontent-serverApp-c21] a[_ngcontent-serverApp-c21] {
  padding: 0px 10px;
}

/*Home menu*/
.bg-landing-banner {
  top: 0;
}

.user_top span.welcome {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
  text-transform: capitalize;
}

.user_top span.welcome_txt {
  line-height: 1rem;
  display: block;
  font-size: 14px;
}

.user_top span.welcome_user {
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
}

.user_top img {
  display: inline-block;
  vertical-align: middle;
}

#header ul li a {
  font-weight: bold;
}

.mobile_menu.home_pg {
  width: 100%;
}

.toggle_menu .lg\:w-auto {
  width: 42%;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #087ac5;
  border: solid 1px #087ac5;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  color: #ffffff;
}

.pi-chevron-up::before,
.pi-chevron-down::before {
  background: #fff;
  border-radius: 100%;
  color: #495057;
  padding: 5px;
  font-size: 0.7rem;
}

// .web_linkk {
//   min-height: 38px;
//   display: flex;
//   margin-bottom: 10px;
// }

.web_linkk .web_url {
  margin-top: -15px;
  display: flex;
}

plusicon.p-element.p-icon-wrapper {
  margin-left: -2px;
  color: #fff;
}

plusicon.p-element.p-icon-wrapper svg g path {
  fill: #fff;
}

.view_front .h-12 {
  height: auto;
  width: auto;
  max-width: 150px;
}

.preview_logo .h-12 {
  height: auto;
  width: auto;
  max-width: 150px;
}

.relative.transform.overflow-hidden.bg-white {
  width: 100%;
}

.demo_video_blk youtube-player {
  height: 500px;
  display: flex;
}

.demo_video_blk iframe {
  width: 100%;
  height: 500px;
}

.svg_icons svg {
  background: #fff;
  padding: 6px;
  margin-top: 15px;
}

.contact_box_new {
  min-height: 164px;
  min-width: -webkit-fill-available;
}

.checkbox_toggle {
  background: #fff;
  padding: 15px 13px;
  border-radius: 5px;
  z-index: 1;
  position: absolute;
  box-shadow: -1px 3px 8px 0px #959494;
  width: 160px;
}

.checkbox_toggle button[type="submit"] {
  background: rgb(59 130 246 / 1);
  color: #fff;
  padding: 5px 12px;
  margin-top: -4px;
  border-radius: 4px;
  text-align: center;
  width: 100px;
  margin-left: 0;
}

.checkbox_toggle .check_list > div {
  margin-bottom: 4px;
  margin-left: 5px;
}

.checkbox_toggle .check_list label {
  margin-left: 5px;
  word-break: break-word;
}

.svg_icons {
  .checkbox_toggle svg.check_button {
    text-align: center;
    background: #000;
    margin-top: 0;
    margin-bottom: 10px;
    border-radius: 4px;

    button {
      margin-top: 4px;
    }
  }

  .checkbox_toggle li.check_button {
    text-align: center;
    margin-top: 15px;
  }
}

.flex.user_imgs.contact_new svg {
  padding: 3px;
  width: 1rem;
  height: 1rem;
}

.user_imgs.contact_new a {
  top: -18px;
  right: -6px;
  box-shadow: inherit;
}

.user_imgs.contact_new .p-ripple.p-element.p-button.p-component {
  margin-right: 0.7rem;
  position: relative;
}

.view_block_contact .flex.flex-wrap.mt-4 button {
  color: #fff;
  padding: 5px 10px;
  width: 38px;
  margin-top: -4px;
}

.checkbox_toggle .close_button {
  display: flex;
  justify-content: flex-end;
}

.chat-container {
  word-break: break-all;
}

.rtl .mr-20 {
  margin-left: 5rem;
  margin-right: 0;
}

.rtl .mr-2 {
  margin-left: 0.5rem;
  margin-right: 0;
}

.rtl .ml-2 {
  margin-right: 0.5rem;
  margin-left: 0;
}

.rtl .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.5rem * var(--tw-space-x-reverse));
  margin-right: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.rtl .mr-3 {
  margin-left: 0.75rem;
  margin-right: 0;
}

.rtl .mt-3.mr-2 {
  margin-right: 0.5rem;
  margin-left: 0;
}

.rtl .mr-10 {
  margin-left: 2.5rem;
  margin-right: 0;
}

.rtl .ml-3 {
  margin-right: 1.25rem;
  margin-left: 0;
}

// .select_wrapper select {
//     -webkit-appearance: none;
//     appearance: none;
// }
.select_wrapper {
  position: relative;
}

// .select_wrapper::after {
//     content: "▼";
//     font-size: 1rem;
//     top: 11px;
//     right: 10px;
//     position: absolute;
// }
.p-calendar .p-inputtext {
  padding: 0.68rem 0.75rem !important;
}

.contact_search [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: 0;
}

.view_block_contact .chat-container {
  padding: 0 20px 10px 0 !important;
  overflow-y: auto !important;
}

.view_block_contact button.bg-primary.plus_button {
  color: #fff;
  padding: 0 10px;
  width: 38px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.view_block_contact {
  width: 68%;
}

.contact_search input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.svg_icons .del_icon svg {
  padding: 0;
}

.card .p-element .p-inputtext {
  background: transparent;
}

.user_inpbox i.pi.pi-times-circle {
  margin-right: 12px;
}

.contact_boxes:nth-child(3n) {
  margin: 0;
}

.c_buttons .p-button {
  background: transparent;
  border: none;
}

.labels .p-button {
  border: none;

  &:hover {
    border: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.birthday .p-calendar .p-inputtext {
  padding: 0.68rem 0.75rem !important;
  border-radius: 6px;
  height: 36px;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.chat_box .chat-container {
  height: 48vh !important;
}

.view_block_contact .add_note {
  border-radius: 1.25rem;
}

.date_block {
  // border-right: 1px solid #ccc;
  // padding-right: 40px;
  .card .p-element .p-inputtext {
    height: 30px;
    margin-left: 2%;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    width: 100px;
  }
}

.custom_label {
  // border-right: 1px solid #ccc;
  padding-right: 40px;
  padding-left: 30px;

  label {
    font-size: 14px;
  }
}

select#selectedUser {
  margin-left: 1%;
}

.user_inpbox {
  select {
    height: 36px;
  }
}

.plus_button {
  font-size: 22px;
  font-weight: bold;
}

.user_imgs.contact_new button {
  border-radius: 6px !important;
}

.date_block .card .p-element .p-inputtext {
  height: 36px;
  border-radius: 6px;
  width: 100%;
}

.date_block .card .p-element.date_cal .p-inputtext {
  margin-left: 6%;
}

.contact_search [type="search"] {
  height: 36px;
}

.notify_block {
  padding-left: 30px;
}

.user_inpbox .pi-times-circle:before {
  margin-right: 5px;
}

.custom_add.flex input {
  height: 36px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.checkbox_toggle .close_button svg {
  background-color: #000;
  border-radius: 6px;
  padding: 3px;
}

.view_front .block.view_block > div {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  background-color: #fff;
  box-sizing: border-box;
  overflow: auto;
  border-radius: 1.25rem;
  min-height: 620px;
}

.block.view_block > div {
  box-shadow: inherit;
  background-color: transparent;
  box-sizing: border-box;
  overflow: auto;
  border-radius: 1.25rem;
  min-height: auto;
}

.profile_link {
  word-break: break-all;
}

.contact_remainder .p-dialog-content {
  overflow-y: inherit;
}

.p-dialog-content .remind_inner {
  overflow-y: auto;
  height: 330px;
}

.item-start .first_row {
  border-bottom: 1px solid #ccc;
}

.date_block p-calendar.p-element .p-calendar {
  width: 100%;
}

.notification {
  width: 40px;
}

.msg_block .ql-editor p {
  word-break: break-all;
}

.contact_boxes .checkbox_toggle {
  right: 0;
}

.notifi_detail {
  word-break: break-word;
}

.rtl .contact_boxes .-right-4 {
  right: 95%;
  left: 0;
}

.rtl .lg\:mr-5 {
  margin-left: 1.25rem;
  margin-right: 0;
}

.rtl .contact_boxes.xl\:mr-\[5\%\] {
  margin-left: 5%;
  margin-right: 0;
}

.rtl .contact_boxes:nth-child(3n) {
  margin: 0;
}

.rtl .first_row.lg\:mr-5 {
  margin-left: 1.25rem;
  margin-right: 0;
}

.rtl .custom_label.lg\:mr-5 {
  margin-left: 1.25rem;
  margin-right: 0;
}

.rtl .date_block .card .p-element.date_cal .p-inputtext {
  margin-left: 0;
  margin-right: 6%;
}

.rtl .date_block .card.ml-3 {
  margin-left: 1.25rem;
  margin-right: 0;
}

.rtl .date_block .card:nth-child(2) {
  margin-left: 1.25rem !important;
}

.rtl .date_block .xl\:ml-6 {
  margin-right: 1.5rem;
  margin-left: 0;
}

.rtl .date_block .p-input-icon-right > i:last-of-type {
  right: 87%;
}

.rtl .view_btn.xl\:ml-3 {
  margin-right: 0.75rem;
  margin-left: 0;
}

.rtl .date_block select.pr-8 {
  padding-left: 2rem;
}

.rtl .date_block select.pl-2 {
  padding-right: 0.5rem;
}

.custom-select.selected {
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 90px;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ffffff;
  padding: 4px 5px;
  padding-right: auto;
  font-size: 0.73rem;
  color: #ffffff;
  border-radius: 4px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="%23ffffff" d="M7 10l5 5 5-5z"/></svg>');
  outline: none !important;
  background-repeat: no-repeat;
  background-position: right 5px center;
  text-align: left !important;
}
.rtl .custom-select.selected {
  text-align: right !important;
  background-position: left 5px center !important;
}
.custom-select.selected:focus {
  border-color: #ffffff;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
}
.custom-select.selected option {
  color: #333;
}
.customed-checkbox:checked {
  background-color: #087ac5;
  border-color: #087ac5;
}
.customed-checkbox {
  all: unset;
  -webkit-appearance: none;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #a1a1a1;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 0.25rem;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.customed-checkbox:checked::before {
  content: "✔";
  color: white !important;
  font-family: Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1;
  display: inline-block;
  width: auto;
  height: auto;
}

.customed-checkbox:disabled {
  background-color: #d1d1d1;
  border-color: #a1a1a1;
  cursor: not-allowed;
}

.customed-checkbox:disabled::before {
  color: #a1a1a1;
}

.customed-radio:checked {
  background-color: #ffffff;
  border-color: #087ac5;
}
.customed-radio {
  appearance: none;
  background-color: transparent;
  border: 2px solid #a1a1a1;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.customed-radio:checked::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #087ac5;
  border-radius: 50%;
  display: block;
}
.customed-radio:disabled {
  border-color: #dfdfdf;
  cursor: not-allowed;
}

.customed-radio:disabled::before {
  background-color: #a1a1a1;
}

.rtl .date_block .user_inpbox.p-input-icon-right > i:last-of-type {
  right: 80%;

  @media only screen and (max-width: 1440px) {
    right: 75%;
  }
}

.rtl .date_block .p-calendar .p-datepicker {
  min-width: 100%;
  right: 0 !important;
  left: inherit !important;
  top: 10px !important;
}

.rtl .notification {
  width: 40px;
  top: 0;
  position: relative;

  @media only screen and (max-width: 1440px) {
    top: -11px;
  }
}

.rtl .notification svg {
  margin-top: -18px;
}

.rtl .notify_count.top-\[-15\%\] {
  top: -24px;

  @media only screen and (max-width: 1440px) {
    top: -7px;
  }
}

.rtl .custom_add button.bg-primary:last-child {
  margin-left: 1.25rem;
  margin-right: 0;
}

.rtl .date_block .card .xl\:ml-3 {
  margin-right: 0.75rem;
  margin-left: 0;
}

.rtl .card.justify-end {
  justify-content: flex-start;
}

.user_imgs.contact_new button.active {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.card_inactive .cards_select {
  display: none;
}

.card_inactive .date_sec {
  width: 100%;
}

.check_list .tooltip {
  top: -38px;
  width: 200px;
  text-align: center;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c_buttons .tooltip {
  top: -38px;
  width: 100%;
  text-align: center;
  width: 200px;
}

.remind_checkbox svg.close_icon {
  background-color: #087ac5;
  border-radius: 100%;
  padding: 5px;
  margin-left: 15px;
  fill: #fff;
  font-weight: bold;
}

span.center_line {
  padding: 0 8px;
}

.custom_tagss .p-dialog.p-element .p-dialog-header {
  background: #000;
  color: #fff;
  padding: 0.7rem 1.25rem;

  svg.p-dialog-header-close-icon.p-icon {
    color: #fff;
  }

  .p-dialog-title {
    color: #fff;
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #fff;
  border-color: transparent;
  background: #000;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover svg {
  color: #fff;
}

.date_block .card .p-element .p-datepicker-group-container span {
  width: 2.5rem;
}

.p-dialog-header-close svg {
  color: #000;
}

.download_text {
  margin-top: -10px;
}

.del_icon .p-dialog-mask.p-component-overlay {
  background: rgb(0 0 0 / 10%) !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover svg {
  color: #fff;
  background: #000;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  margin-top: 0;
}

.svg_icons .del_icon svg {
  padding: 0;
  margin-top: 2px;
}

.remind_on a {
  display: inline-block;
  vertical-align: middle;

  svg {
    margin-top: -4px;
  }
}

.p-datepicker:not(.p-datepicker-inline) {
  width: 320px !important;
  max-width: 320px !important;
  overflow-x: auto;
}
.date-picker {
  .p-datepicker:not(.p-datepicker-inline) {
    bottom: 30px !important;
    top: auto !important;
    left: -24px !important;
  }
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker table td > span {
  width: 2.2rem;
  height: 2.2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  font-size: 1rem;
}

.notify_block.notify_mobile {
  display: none;
}

.card_filters {
  display: none !important;
}

.custom_label_sec.labels_blk.desk_view {
  display: flex;
}

/*Responsive*/

@media only screen and (max-width: 1920px) {
  .p-dialog {
    left: 0;
  }

  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .p-dialog {
    left: 0;
  }

  .notify_block {
    padding-left: 18px;
  }

  .notification span.text-base {
    font-size: 14px;
    line-height: 1.5;
  }

  .date_block .card:nth-child(2) {
    margin-left: 0 !important;
  }

  .contact_search .relative {
    width: 100%;
  }

  #menu-button {
    padding: 6px 10px 6px 7px;
  }

  .date_block .card .p-element.date_cal .p-inputtext {
    margin-left: 0.75rem;
  }

  .date_block .card .p-element .p-inputtext {
    width: 84%;
  }

  .date_block .card .p-element span {
    width: 100%;
  }

  // .date_block .card:nth-child(2) {
  //     width: 50%;
  // }
}

@media only screen and (max-width: 1440px) {
  .p-dialog {
    left: 0;
  }

  .card.flex p {
    font-size: 14px;
  }

  .user_inpbox p {
    font-size: 14px;
    width: 29%;
  }

  .flex.contact_search label {
    font-size: 14px;
  }

  .contact_search .relative {
    width: 100%;
  }

  .custom_label button.plus_button {
    margin-right: 0.75rem;
  }

  .date_block {
    // padding-right: 27px;
    padding-right: 0;
  }

  .custom_label {
    padding-right: 5px;
    padding-left: 5px;
  }

  .notify_block {
    padding-left: 5px;
  }

  .notification li {
    margin-right: 7px !important;
  }

  .notification {
    margin-top: 0;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .notify_block a {
    margin-right: 5px !important;
  }

  .notify_block .justify-end .text-base {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .user_imgs.contact_new button {
    border-radius: 6px !important;
    font-size: 14px;
  }

  select#selectedUser {
    margin-left: 1%;
  }

  .plus_button {
    font-size: 22px;
    width: 2rem;
    height: 2rem;
    margin-top: 9px;
  }

  .custom_add.flex input {
    margin-bottom: 10px;
  }

  .date_block .card:nth-child(2) {
    margin-left: 0 !important;
  }

  // .custom_label {
  //     min-height: 132px;
  // }
  .date_block .card .p-element .p-inputtext {
    width: 84%;
  }

  #menu-button {
    padding: 3px 8px 3px 5px;
  }

  // .item-start {
  //     .first_row {
  //         .date_block {
  //             width: 90%;
  //             display: block;
  //             .card_id_blk {
  //                 width: 62%;
  //             }
  //             .searchh_blk {
  //                 width: 38%;
  //                 margin: 15px 0 0;
  //             }
  //         }
  //         .notify_block {
  //             width: 10%;
  //             display: block;
  //         }
  //         .view_btn {
  //             width: 27%;
  //         }
  //     }
  // }
}

@media only screen and (max-width: 1366px) {
  .p-dialog {
    left: -7px;
  }
}

@media only screen and (max-width: 1170px) {
  // .qrcode canvas {
  //     width: 50% !important;
  //     height: 50% !important;
  // }
  youtube-player iframe {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .flex-1.lg\:ml-60 {
    margin-left: 15rem;
  }

  // .item-start .first_row {
  //     border-bottom: 1px solid #ccc;
  //     display: block;
  // }
  // .item-start {
  //     .date_block {
  //         width: 100%;
  //         display: block;
  //         .card_id_blk {
  //             width: 100%;
  //         }
  //         .searchh_blk {
  //             width: 100%;
  //             margin: 15px 0 0;
  //         }
  //     }
  //     .notify_block {
  //         width: 100%;
  //         display: block;
  //     }
  //     .view_btn {
  //         width: 27%;
  //     }
  // }
}

@media only screen and (max-width: 1023px) {
  .video_blk youtube-player {
    height: 300px;

    iframe {
      height: 400px;
    }
  }

  .mobile_menu {
    position: fixed;
    box-shadow: inherit;
    top: 0;
    left: 0;
    z-index: 21;
  }

  .mobile_menu.home_pg .toggle_menu .w-full.container {
    margin: 0;
    max-width: 100%;
  }

  .mobile_menu.home_pg .toggle_menu .w-full.container li {
    display: inline-block;
  }

  .mobile_menu.home_pg .flex-grow li:first-child {
    margin-right: 10px;
  }

  .mobile_menu.home_pg ul.list-reset li a {
    font-weight: bold;
    padding: 0 1px;
  }

  .mobile_menu.home_pg .toggle_menu .lg\:w-auto {
    width: 50px;
  }

  .mobile_menu.home_pg .toggle_menu .w-full.container .pl-3 {
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  .flex.min-h-full.mob_card {
    min-height: inherit;
    flex: none;
  }
}

@media only screen and (max-width: 767px) {
  form .bg-white .add_info.ng-star-inserted {
    border: 1px solid #d1d5db;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
    padding: 10px 15px;
    margin-bottom: 20px;
    border-radius: 0.125rem;
  }

  .mobile_header {
    padding-left: 3rem;
    margin: 0;
  }

  .card_filters {
    display: block !important;

    .p-accordion .p-accordion-content {
      padding: 1.25rem 0.75rem;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
      border: 1px solid rgb(209, 213, 219);
      height: 38px;
    }

    .p-accordion
      .p-accordion-header
      .p-accordion-header-link
      .p-accordion-toggle-icon {
      display: block;
    }

    .custom_label_sec {
      display: block;
    }
  }

  .custom_label_sec.labels_blk.desk_view {
    display: none;
  }

  .search_cont {
    display: flex;
    align-items: center;
    justify-content: center;

    .view_btn {
      width: 25%;

      button {
        width: auto;
        height: 34px;
        align-items: flex-start;
        display: flex;
        justify-content: flex-end;
        margin-top: -5px;
      }
    }

    .contact_search {
      margin: 0;
      width: 75%;
    }
  }

  .item-start .first_row {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .download_text > div {
    justify-content: center;
  }

  .card_label {
    label {
      width: 100%;
      margin-bottom: 22px;
    }
  }

  .download_text {
    margin: 20px 0 10px;
    width: 100%;
    align-items: self-start;
    justify-content: center;
  }

  .contact_listt > div {
    padding: 0;
  }

  .contact_remainder .p-dialog .radio_block {
    overflow: scroll;
  }

  .remind_on {
    margin: 10px 0;
    display: block;
    width: 128px;
  }

  .filters_desktop {
    display: none;
  }

  .contact_remainder .p-dialog .radio_block span {
    width: 90px;
  }

  .flex.order_topcontent .flex-row {
    flex-direction: inherit;
    width: 90%;
    display: block;
    flex-wrap: wrap;
  }

  .lg\:w-2\/3.block.mb-5.mt-3 {
    max-width: 64%;
  }

  .chat-container .justify-between .flex.notifi_detail {
    display: flex;
  }

  .chat-container .flex.items-center {
    width: 100%;
    display: block;

    .remind_on {
      width: 144px;
    }
  }

  .chat-container .justify-between .flex.px-4 {
    display: flex;
  }

  p-selectbutton > div {
    display: flex;
  }

  .flex.user_imgs {
    margin-bottom: 16px;
  }

  // p-toast .p-toast-top-right {
  //   top: 20px;
  //   right: 0;
  //   left: 0;
  //   width: auto;
  //   margin: 0 auto;
  //   padding: 10px;
  // }

  .top_breadcrumb .mr-10 {
    margin-right: 20px;
  }

  .order_table {
    width: 100%;
  }

  .order_topcontent p {
    width: 100%;
  }

  .p-dialog.p-component {
    width: 80% !important;
  }

  .mobile_menu {
    position: fixed;
    box-shadow: inherit;
    top: 0;
    z-index: 21;
  }

  .mobile_sticky {
    box-shadow: inherit;
  }

  nav.bg-black {
    height: 100%;
    background: rgb(0 0 0 / 83%);
  }

  .signup_form {
    width: 100%;
    max-width: 100%;
  }

  .card_box {
    width: 85%;
    margin: 15px auto;
  }

  .p-dialog.p-element .p-dialog-header {
    padding: 1.25rem;
  }

  p-dialog.p-element.shipping_addr .p-dialog-content {
    padding: 0 1rem;
  }

  .p-dialog.p-element .p-dialog-content .bg-white.ship_addr {
    padding: 0 0 10px;
  }

  nav.bg-black.toggle_menu {
    padding-top: 10px;
  }

  .preview_butt {
    display: none;
  }

  .prof_link {
    width: 100%;
  }

  .prof_link > div {
    width: 100%;
  }

  .prof_link > div span {
    width: 100%;
  }

  .prof_link > div input {
    width: 100%;
  }

  .card_label {
    width: 85%;
    margin: 20px auto -15px;
  }

  input#default-search {
    width: 85%;
    margin: 10px auto 15px;
  }

  .search_def .absolute {
    left: 0;
  }

  .view_front .top_breadcrumb li p {
    padding-left: 5px;
    font-size: 12px;
  }

  .view_block > div .f_card {
    width: 100%;
  }

  .contact_boxes .created_on .justify-end {
    width: 50%;
  }

  .card
    .p-paginator
    p-dropdown.p-element.p-inputwrapper.p-inputwrapper-filled.ng-untouched.ng-star-inserted.ng-dirty.ng-valid {
    width: 80px;
  }

  .card .p-paginator .p-element .p-inputtext {
    background: transparent;
    padding: 10px 5px !important;
  }

  .card.flex.justify-end {
    justify-content: center;
  }

  .p-dropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .card .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;
    border-radius: 5px;
  }

  .mobile_sticky {
    box-shadow: inherit;
    align-items: center;
  }

  .view_front .top_breadcrumb {
    position: relative;
  }

  .b-navbar__toggle {
    justify-content: flex-start;
    margin-top: 25px;
  }

  .mobile_menu.home_pg {
    left: 0;
  }

  .mobile_menu.home_pg .w-full.container.flex.flex-wrap {
    display: block;
  }

  .mobile_menu.home_pg .b-navbar__icon svg {
    fill: #fff;
    position: relative;
    z-index: 3000;
  }

  .mobile_menu.home_pg .bg-landing-banner {
    top: 70px;
  }

  .mobile_menu.home_pg .flex.items-center {
    display: block;
    width: auto;
    margin: 0;
  }

  .mobile_menu.home_pg .w-8 {
    width: 20%;
    display: block;
    float: none;
  }

  .mobile_menu.home_pg div#nav-content {
    padding: 0;
  }

  .mobile_menu.home_pg ul li a {
    font-weight: bold;
    padding: 10px 12px;
  }

  .mobile_menu.home_pg ul.list-reset li a {
    padding: 10px 0;
  }

  .mobile_menu.home_pg ul {
    padding: 0 12px;
    display: block;
  }

  .mobile_menu.home_pg .relative.inline-block.text-left {
    text-align: center;
    margin-top: 25px;
  }

  .mobile_menu.home_pg ul.flex-grow li:last-child a {
    padding: 0;
  }

  .mobile_menu.home_pg {
    z-index: 100;
  }

  .mobile_menu.home_pg .b-navbar__toggle {
    justify-content: space-between;
    margin: 0;
    background: #000;
    padding: 10px 20px 15px;
    position: relative;
    z-index: 2000;
  }

  .mobile-menu-header {
    min-height: 100px !important;
  }

  h1.text-3xl.font-bold.text-white {
    padding-top: 25px;
  }

  .mobile_menu.home_pg ul li a.user_top {
    padding: 0;
  }

  .mobile_menu.home_pg .toggle_menu .w-full.container li {
    display: block;
  }

  .mobile_menu.home_pg .toggle_menu .w-full.container .pl-3 {
    padding: 0.75rem;
  }

  .contact_search .absolute {
    left: 8%;
  }

  .view_block.w-full p {
    word-break: break-word;
  }

  .contact_detail {
    display: block;
    width: 100%;
  }

  .view_block_contact {
    margin: 0;
  }

  .view_block_contact h3 {
    word-break: break-all;
    font-size: 15px;
  }

  .view_block_contact textarea {
    width: 100%;
  }

  .contact_detail .view_block {
    width: 100%;
  }

  .card_box .h-32 {
    height: 6.6rem;
  }

  .card_box .flex .mt-2 {
    margin-top: 0.2rem;
  }

  // .bg-black a.justify-center {
  //   padding: 15px 0 10px;
  // }

  // .bg-black {
  //   padding-top: 10px;
  // }

  .date_block .card .p-element .p-inputtext {
    width: 100%;
    margin-left: 42px;
  }

  .card.flex p {
    width: 15%;
  }

  .date_block .card .p-element.date_cal .p-inputtext {
    margin-left: 18px;
  }

  .card .p-calendar span {
    width: 100%;
  }

  .card .p-calendar {
    width: 100%;
  }

  .card.flex {
    width: 100%;
  }

  .card .p-calendar .p-datepicker {
    top: 40px !important;
    left: -5px !important;
  }

  .date_block .card:nth-child(2) {
    margin-top: 12px;
    width: 100%;
  }

  .date_block .card .p-element .p-inputtext {
    margin-left: 18px;
  }

  .date_block {
    padding: 0 20px 0 15px !important;
  }

  .custom_label {
    padding: 0 15px;
    margin-top: 0;
  }

  .contact_search {
    margin: 0;
  }

  .contact_search .relative .ml-3 {
    margin-left: 0;
  }

  input#default-search {
    width: 100%;
  }

  .notify_block {
    justify-content: center;
    margin-left: 10px;
  }

  .notify_block.notify_mobile {
    display: block;
    margin-top: 28px;
  }

  .notify_block.notify_desktop {
    display: none;
  }

  .user_inpbox p {
    width: 15%;
  }

  .notification {
    margin: 0;
  }

  .custom_label_sec {
    width: auto;
    height: auto;
    padding: 18px 15px 12px;
  }

  .custom_label_sec .lab_sec {
    width: auto;
    display: flex;
  }

  .cards_select {
    margin-top: 13px;
  }

  .contact_search .absolute {
    left: 0;
    top: -5px;
  }

  .contact_search .p-input-icon-right > i:last-of-type {
    margin-top: -10px;
  }

  .contact_listt {
    margin: 0 auto;

    .contact_boxes {
      margin: 0;
      width: 100%;
    }
  }

  select#selectedUser {
    margin-left: 4%;
    width: 84%;
  }

  .add_notes_popup
    .p-dialog.p-dialog.p-element
    .p-dialog-content
    .note_content {
    padding: 0.65rem;
  }

  .view_block_contact {
    width: 100%;
    padding: 0.75rem;
  }

  .remind-content.flex.items-center {
    display: block;
  }

  .remaind_block button {
    margin-bottom: 10px;
  }

  .add_notes_popup .p-dialog.p-component {
    width: 96% !important;
  }
}

@media only screen and (max-width: 640px) {
  .view_block {
    width: 100%;
    margin-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .p-steps .p-steps-item .p-menuitem-link {
    margin-right: 10px !important;
  }

  .p-dialog-mask.p-component-overlay {
    width: 100%;
  }

  .fixed.inset-0.bg-gray-900.bg-opacity-75.transition-opacity {
    background: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .p-dialog {
    width: 75%;
    max-width: 100%;
    left: 0;
  }

  .flex.w-full.social_icons li.mr-3 {
    margin-right: 9%;
  }

  .video_blk youtube-player {
    height: 300px;

    iframe {
      height: 300px;
    }
  }

  .pop_input input {
    width: 100%;
  }

  .whatsapp_icon .pop_input input {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .order_table {
    width: 80%;
  }

  .order_topcontent p {
    width: 90%;
  }

  .login_form .otp_box .p-inputtext {
    padding: 0.2rem 0.25rem !important;
    width: 26px !important;
    height: 26px !important;
  }
}

@media only screen and (max-width: 420px) {
  .w-full.mt-32 {
    margin-top: 7rem;
  }

  .video_blk youtube-player {
    height: 300px;

    iframe {
      height: 210px;
    }
  }

  .demo_video_blk youtube-player {
    height: auto;
    display: flex;
  }

  .demo_video_blk iframe {
    width: 100%;
    height: auto;
  }

  p-dialog.p-element.shipping_addr .p-dialog-content {
    padding: 0 1rem 1rem;
  }

  .p-dialog.p-element .p-dialog-content .bg-white label {
    font-size: 12px;
  }

  .order_table {
    width: 70%;
  }

  .order_topcontent p {
    width: 90%;
  }

  p-dialog.p-element .text-sm {
    font-size: 13px;
  }
}

@media only screen and (max-width: 580px) {
  .top_breadcrumb {
    flex-direction: column;
    gap: 10px;
  }
  .list-margin-right {
    margin-right: 7px !important;
  }
  .multiLangPopup .p-dialog {
    min-width: 280px;
  }
}
@media only screen and (max-width: 330px) {
  .w-full.mt-32 {
    margin-top: 7rem;
  }
}

/** Safari */

@media screen and (-webkit-min-device-pixel-ratio: 4) {
  /* Safari 9.1+ specific styles */
  .w-full.mt-32 {
    margin-top: 9rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 5/4) {
  /* Safari 10.1+ specific styles */
  .w-full.mt-32 {
    margin-top: 9rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 16/9) {
  .w-full.mt-32 {
    margin-top: 9rem;
  }
}

/* Safari versions 16.2 and lower */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    /* Safari 16.2 and lower specific styles */
    .w-full.mt-32 {
      margin-top: 9rem;
    }
  }
}

/* Safari 16.3 and higher */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    /* Safari 16.3+ specific styles */
    .w-full.mt-32 {
      margin-top: 9rem;
    }

    body {
      position: relative;
      top: 0;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari and Chrome */
  .w-full.mt-32 {
    margin-top: 9rem;
  }
}

/*Safari Iphone*/
@media only screen and (max-width: 414px) and (max-height: 812px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .w-full.mt-32 {
    margin-top: 9rem;
  }
}

/*Moz Only*/
@supports selector(:-moz-is-html) {
  .w-full.mt-32 {
    margin-top: 7rem;
  }

  select#selectedUser {
    overflow: hidden;
  }
}

@-moz-document url-prefix() {
  .select_wrapper.user_inpbox i {
    display: none;
  }
}

/*Chrome Only*/
@supports (not (-webkit-hyphens: none)) and (not (-moz-appearance: none)) and
  (list-style-type: "*") {
  .w-full.mt-32 {
    margin-top: 7rem;
  }
}

/*Firefox Only*/
@-moz-document url-prefix() {
  .w-full.mt-32 {
    margin-top: 7rem;
  }
}
